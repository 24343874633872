@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'MangoGrotesque';
  src: url('/public/fonts/Web-TT/MangoGrotesque-Black.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi-Regular';
  src: url('/public/fonts/Satoshi-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

body{
  @apply  text-gray-900;
  @apply min-h-screen;
  background: #ffffff;
  font-family: "Satoshi-Regular";
}

a{
  
  font-family: "Satoshi-Regular";
}

h1, h2, h3, h4, h5, h6 {
  font-family: "MangoGrotesque";

}

p{
  font-family: "Satoshi-Regular";
}

.work-anibe{
  font-family: "MangoGrotesque";
}


btn:is(input[type="checkbox"]:checked), .btn:is(input[type="radio"]:checked) {
  --tw-border-opacity: 1;
  border-color: #0186ef !important;
  --tw-bg-opacity: 1;
  background-color: #0186ef !important;
  --tw-text-opacity: 1;
  color: var(--fallback-pc, oklch(var(--pc) / var(--tw-text-opacity)));
}

.login{

  @apply min-h-screen;
  background: #f2f2f2;
  font-family: "Satoshi-Regular";
}

.dashboard{

  @apply min-h-screen;
  background: #f2f2f2;
  font-family: "Satoshi-Regular";
}


.alert-success {
  border-color:#0186ef !important;
  --tw-text-opacity: 1;
  color: #ffffff !important;
  --alert-bg: #0186ef !important;
  --alert-bg-mix:  #0186ef !important;
}


